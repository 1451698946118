<template>
  <div class="my-2">
    <v-card class="mt-2">
      <v-card-text class="d-sm-flex align-start text-body-2">
        <div class="d-flex flex-column mr-2 align-center">
          <v-avatar size="90">
            <v-img :src="profileId | avatarUrl('thumb', hasImage, true)"></v-img>
            <!--            <v-img src="/images/avatars/avatar1.svg"></v-img>-->
          </v-avatar>
          <br/>
          <div class="justify-center">
            <v-btn small @click="uploadAvatar">Сменить аватар</v-btn>
          </div>
          <my-dialog-upload-profile-avatar
            v-model="showDialogUploadAvatar"
            :on-success-upload="function(){fetchProfile()}"
          ></my-dialog-upload-profile-avatar>
        </div>
        <div class="flex-grow-1">
          <v-form ref="form" v-model="form.formValidity" @submit.prevent="updateProfile">
            <v-text-field
              v-model="form.data.fullName"
              :rules="form.rules.fullName"
              label="Полное имя"
            ></v-text-field>
            <v-btn color="primary" class="mt-2" :disabled="!form.formValidity" type="submit">Сохранить</v-btn>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
    <v-expansion-panels v-model="panelActions" class="mt-2" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="title">Действия</v-expansion-panel-header>
        <v-expansion-panel-content class="text-body-2">
          <div class="mb2">
            <div class="subtitle-1">Изменить пароль</div>
            <div class="mb-2">Вам будет предложено ввести старый пароль</div>
            <v-btn class="my-2" @click.stop="showPasswordDialog = true">
              <v-icon left>
                mdi-lock
              </v-icon>
              Сменить пароль
            </v-btn>
          </div>
          <v-divider class="my-2"></v-divider>
          <div class="mb2">
            <div class="subtitle-1 error--text">Закрыть аккаунт</div>
            <div class="mb-2">Это действие необратимо, после того как вы откажитесь от своего аккаунта ваши данные
              будут удалены.
            </div>
            <v-btn color="error" class="my-2" @click.stop="showCloseAccountDialog = true">Удалить мой аккаунт</v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels v-model="panelMetadata" class="mt-2" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="title">Метадата</v-expansion-panel-header>
        <v-expansion-panel-content class="text-body-2">
          <div class="mb-2">
            <div class="text-caption grey--text text--darken-2">ID</div>
            <div>{{ metadata.id }}</div>
          </div>
          <div class="mb-2">
            <div class="text-caption grey--text text--darken-2">Создан</div>
            <div>{{ metadata.createdAt | formatDateTime }}</div>
          </div>
          <div class="mb-2">
            <div class="text-caption grey--text text--darken-2">Изменен</div>
            <div>{{ metadata.updatedAt | formatDateTime }}</div>
          </div>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="showPasswordDialog" persistent max-width="600px">
      <v-card>
        <v-form v-model="formChangePassword.formValidity" @submit.prevent="changePassword">
          <v-card-title class="title">Смена пароля</v-card-title>
          <v-card-text class="my-2">
            <my-input-password
              v-model="formChangePassword.data.password"
              autocomplete="off"
              :rules="formChangePassword.rules.password"
              label="Ваш текущий пароль"
              required
            ></my-input-password>
            <my-input-password
              v-model="formChangePassword.data.newPassword1"
              :rules="formChangePassword.rules.newPassword1"
              type="password"
              label="Новый пароль"
              autocomplete="new-password"
            ></my-input-password>
            <my-input-password
              v-model="formChangePassword.data.newPassword2"
              autocomplete="off"
              :rules="validateNewPassword"
              type="password"
              label="Повторите ввод нового пароля"
            ></my-input-password>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" :disabled="!formChangePassword.formValidity" type="submit">
              Отправить
            </v-btn>
            <v-btn text @click="showPasswordDialog = false">
              Отмена
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCloseAccountDialog" persistent max-width="600px">
      <v-card>
        <v-form v-model="formCloseAccount.formValidity" @submit.prevent="closeAccount">
          <v-card-title class="title error--text">Закрыть аккаунт</v-card-title>
          <v-card-text class="my-2">
            <div class="text-body-2">
              Вы в шаге от закрытия вашего аккаунта. Напоминаем что восстановление вашего аккаунта после закрытия будет
              невозможным.
              Чтобы подтвердить ваши намерения, используйте в качестве прощальной фразы слово <code>SAYONARA</code>
            </div>
            <my-input-password
              v-model="formCloseAccount.data.password"
              autocomplete="off"
              :rules="formCloseAccount.rules.password"
              label="Ваш текущий пароль"
              required
            ></my-input-password>
            <v-text-field
              v-model="formCloseAccount.data.byeMessage"
              :rules="formCloseAccount.rules.byeMessage"
              label="Прощальная фраза"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="error" :disabled="!formCloseAccount.formValidity" type="submit">
              Отправить
            </v-btn>
            <v-btn text @click="showCloseAccountDialog = false">
              Отмена
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastService from '@/service/ToastService'
import LoginProfileService from '@/service/LoginProfileService'
import MyInputPassword from '@/components/ui/input/MyInputPassword'
import MyDialogUploadProfileAvatar from '@/components/ui/dialog/MyDialogUploadProfileAvatar'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ProfileInfoPage',
  components: { MyDialogUploadProfileAvatar, MyInputPassword },
  data: () => ({
    busy: false,
    panelActions: [],
    panelMetadata: [0],
    form: {
      formValidity: false,
      data: {
        fullName: ''
      },
      rules: {
        fullName: [
          (value) => !!value || 'Поле обязательно должно быть заполнено'
        ]
      }
    },
    showPasswordDialog: false,
    formChangePassword: {
      data: {
        password: '',
        newPassword1: '',
        newPassword2: ''
      },
      rules: {
        password: [
          (value) => !!value || 'Поле обязательно должно быть заполнено'
        ],
        newPassword1: [
          (value) => !!value || 'Поле обязательно должно быть заполнено',
          (value) => !!value && value.length >= 6 || 'Пароль должен быть не менее 6 символов'
        ]
      }
    },
    showCloseAccountDialog: false,
    formCloseAccount: {
      data: {
        password: '',
        byeMessage: ''
      },
      rules: {
        password: [
          (value) => !!value || 'Поле обязательно должно быть заполнено'
        ],
        byeMessage: [
          (value) => (!!value && value === 'SAYONARA') || 'Подтвердите закрытие вашего аккаунта'
        ]
      }
    },
    showDialogUploadAvatar: false
  }),
  computed: {
    ...mapGetters('profile', ['profile', 'hasImage', 'profileId']),
    validateNewPassword() {
      return [
        (v) => (this.formChangePassword.data.newPassword1 === this.formChangePassword.data.newPassword2 || 'Пароли не совпадают')
      ]
    },
    metadata() {
      if (this.profile) {
        return {
          'id': this.profile.id,
          'createdAt': this.profile.meta.createdAt,
          'updatedAt': this.profile.meta.updatedAt
        }
      }

      return {
        'id': '',
        'createdAt': '',
        'updatedAt': ''
      }
    }
  },
  created() {
    this.form.data.fullName = this.profile.fullName
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('profile', ['update', 'fetchProfile']),
    updateProfile() {
      this.busy = true
      this.update({ data: this.form.data })
        .then(() => {
          ToastService.success('Данные сохранены')
        })
        .catch((error) => {
          console.info(error)
          ToastService.error('Что-то пошло не так')
        })
        .finally(() => {
          this.busy = false
        })
    },
    uploadAvatar() {
      this.showDialogUploadAvatar = true
    },
    changePassword() {
      LoginProfileService.changePassword({
        oldPassword: this.formChangePassword.data.password,
        newPassword: this.formChangePassword.data.newPassword1
      })
        .then(() => {
          ToastService.success('Ваш пароль обновлен')
          // close dialog
          this.showPasswordDialog = false
          // clean auth
          this.formChangePassword.data.password = ''
          this.formChangePassword.data.password1 = ''
          this.formChangePassword.data.password2 = ''

          // Make user login again
          this.$router.push({ name: 'auth.signin' })
        })
        .catch((error) => {
          const messages = error.graphQLErrors.map((graphQLError) => graphQLError.message)

          if (messages.includes('WRONG_PASSWORD')) {
            ToastService.warning('Указан неверный пароль')
          } else {
            ToastService.error('Что-то пошло не так.')
            console.error(error)
          }
        })
    },
    closeAccount() {
      LoginProfileService.closeAccount({
        password: this.formCloseAccount.data.password
      })
        .then(() => {
          ToastService.success('Прощайте!')
          // close dialog
          this.showCloseAccountDialog = false
          // clean auth
          this.formCloseAccount.data.password = ''
          this.formCloseAccount.data.byeMessage = ''

          // Make user login again
          this.$router.push({ name: 'auth.signin' })
        })
        .catch((error) => {
          const messages = error.graphQLErrors.map((graphQLError) => graphQLError.message)

          if (messages.includes('WRONG_PASSWORD')) {
            ToastService.warning('Указан неверный пароль')
          } else {
            ToastService.error('Что-то пошло не так.')
            console.error(error)
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
